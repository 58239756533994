import { Component ,ChangeDetectorRef,AfterContentChecked} from "@angular/core";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements AfterContentChecked{
  title = "POST-frontend";
  constructor( private changeDetector: ChangeDetectorRef,){

  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
